// @group fonts -------------------------------------------

import css from '../../assets/font/zcon/css/zcons-codes.css';
import webfonts from '../../assets/font/webfonts/stylesheet.css';


// @group vendor ------------------------------------------

//import '../vendor/purecss/build/pure.css';
import '../../node_modules/purecss/build/base.css';
import '../../node_modules/purecss/build/buttons.css';
import '../../node_modules/purecss/build/forms.css';
import '../../node_modules/purecss/build/grids-core.css';
import '../../node_modules/purecss/build/menus.css';
import '../../node_modules/purecss/build/tables.css';
//import '../vendor/opentip/css/opentip.css';
///import '../vendor/tiny-slider/dist/tiny-slider.css';


// @group base settings ------------------------------------

import './base.less';
import './colors.less';
import './icons.less';


// @group grid - choose one --------------------------------

import './grid/a-grid-base.less';
//import './grid/a-grid-1-4.less';
//import './grid/a-grid-1-6.less';
//import './grid/a-grid-1-8.less';
//import './grid/a-grid-1-12.less';
import './grid/a-grid-1-12-all.less';


// @group elements -----------------------------------------

import './buttons.less';
import './cookiebar.less';
import './forms.less';
import './loading.less';
import './menus.less';
import './styleguide.less';
import './tables.less';
import './tabs.less';


// @group vendor -------------------------------------------
//@import 'v-cookieconsent';


// @group app ---------------------------------------------

import './app/app.less';
import './app/aside.less';
import './app/content.less';
import './app/footer.less';
import './app/header.less';
import './app/nav.less';


// @group atoms -------------------------------------------

import './atoms/badge.less';
import './atoms/close.less';
import './atoms/iframe.less';
import './atoms/progressbar.less';
import './atoms/video.less';


// @group partials -----------------------------------------

import './partials/card.less';
import './partials/carousel.less';
import './partials/checklist.less';
import './partials/modal.less';
import './partials/panel.less';
import './partials/pie.less';
import './partials/tooltip.less';


// @group atoms --------------------------------------------

//@import 'a-imageflip';
//@import 'a-table';
//@import 'a-textfeature';
//@import 'a-downloads';
//@import 'a-unit';
//@import 'a-image';
//@import 'a-spacer';
//@import 'a-circleicon';


// @group reusable items -----------------------------------

//@import 'items/close';
//@import 'items/popup';


// @group pages --------------------------------------------

import './pages/login.less';


// @group components ---------------------------------------

//@import 'components/maintenance';


